import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import logoPoli from '../assets/images/evento/GEP/logo-poli.png'

const LogoBox = ({logo, question = false}) => {
  return(
      <Box sx={{width:'100%', height:question ? '100%' : '50%', p:question ? 5 : 0}}>
          <img style={{ margin:'auto', width:'100%', height:'100%', objectFit:'contain'}} src={logo}/>
      </Box>
  )
}

export default LogoBox
