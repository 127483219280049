import React from "react";
import {AppBar, Box, Container, Stack, Toolbar, Typography, useTheme} from "@mui/material";
import {Textfit} from 'react-textfit';

export default function QuestionBar({tipo, domanda, colore}) {
    const theme = useTheme()
    return (
        <Container sx={{height: '20%'}}>
            <Box sx={{position: 'relative', width: '100%', height:'100%'}}>
                <Textfit mode={'multi'} min={10} max={30}
                         style={{
                             fontWeight: '400',
                             fontFamily: 'Proto Grotesk',
                             width: '100%',
                             height: '100%',
                             textAlign: 'center',
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center',
                             textTransform: 'uppercase',
                         }}>
                    {domanda}
                </Textfit>
            </Box>
        </Container>
    )
}
